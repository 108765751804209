import React from "react";
import styled from "styled-components";

export const CardContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  margin: 1rem;
  @media screen and (min-width: 800px) {
    margin: 1rem 3rem;
  }
`;
