import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import styled from "styled-components";
export const Card = ({ Post }) => {
  const { title, image, slug, excerpt } = Post;
  const CardImage = getImage(image);
  return (
    <Container>
      <Link to={`/blog/${slug}`}>
        <GatsbyImage image={CardImage} alt={title} />
      </Link>
      <div className="content">
        <Title>{title}</Title>
        <Line />
        <Excerpt>{excerpt.excerpt}</Excerpt>
        <More to={`/blog/${slug}`}>Read More</More>
      </div>
    </Container>
  );
};

const Container = styled.div`
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow: hidden;
  img {
    width: 100%;
  }
  .content {
    padding: 1rem;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    opacity: 0.9;
  }
`;

const Title = styled.h3`
  color: #525050;
  @media screen and (min-width: 720px) {
    font-size: 1.5rem;
  }
`;
const Excerpt = styled.p`
  color: #7d7d7d;
  @media screen and (min-width: 720px) {
    font-size: 1.2rem;
  }
  margin-bottom: 0.5rem;
`;

const Line = styled.hr`
  border: 2px solid #525050;
  width: 30px;
  margin: 0.5rem 0;
`;
const More = styled(Link)`
  margin-top: 0.5rem;
  color: var(--primary-color);
`;
