import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import { Card } from "../components/styled/Card";
import { LatestCard } from "../components/styled/LatestCard";
import { CardContainer } from "../components/styled/CardContainer";
import SEO from "../components/Seo";

const BlogPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      Latest: allContentfulBlogPost(
        sort: { order: DESC, fields: date }
        limit: 1
      ) {
        nodes {
          title
          slug
          date
          excerpt {
            excerpt
          }
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      List: allContentfulBlogPost(
        sort: { order: DESC, fields: date }
        skip: 1
      ) {
        nodes {
          title
          slug
          date
          id
          excerpt {
            excerpt
          }
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  `);
  const LatestPost = data.Latest.nodes[0];
  const Posts = data.List.nodes;

  return (
    <Layout location={location}>
      <SEO title="blog" />
      <BlogTitle>Our Latest Blog</BlogTitle>
      <LatestCard LatestPost={LatestPost} />
      <CardContainer>
        {Posts && Posts.map((d) => <Card Post={d} key={d.id} />)}
      </CardContainer>
    </Layout>
  );
};

export default BlogPage;

const BlogTitle = styled.h3`
  margin: 1rem;
  @media screen and (min-width: 800px) {
    margin: 1rem 3rem;
    font-size: 2.5rem;
  }
`;
